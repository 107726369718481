import React, { useEffect, useRef, useState, useCallback } from "react";
import { createRoot } from "react-dom/client";
import loadable from "@loadable/component";
import { AnimatePresence, m } from "framer-motion";
import maplibregl from "maplibre-gl";
import useLang from "@hooks/useLang";
import t from "@utils/t";

import useOpenStudiosArtists from "@staticQueries/OpenStudiosArtistsQuery";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import { Marker, Sidebar } from "@molecules";
import { Toggle } from "@atoms";

const Search = loadable(() => import("../molecules/Search"));

const OpenStudiosMap = React.memo(
  () => {
    const lang = useLang();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const markers = useRef(null);

    const { openStudiosArtists: artists } = useOpenStudiosArtists();
    const filters = useCategoriesData()[
      lang === "en" ? "en" : "es"
    ].openStudiosArtists.sort((a, b) => a.title.localeCompare(b.title));

    const [currentArtist, setCurrentArtist] = useState(null);
    const [activeFilter, setActiveFilter] = useState(filters[0].uid);
    const [filteredItems, setFilteredItems] = useState(artists);
    const [mapReady, setMapReady] = useState(false);

    const [results, setResults] = useState(filteredItems);
    const [query, setQuery] = useState("");

    // filter artists
    const updateFilters = useCallback(
      event => {
        const newFilter = event.target.id;
        setActiveFilter(newFilter);
      },
      [filters]
    );

    // change filtered items every time filters are applied
    useEffect(() => {
      setFilteredItems(
        artists?.filter(item =>
          item.tags.map(tag => tag.uid)?.includes(activeFilter)
        )
      );
    }, [activeFilter]);

    const mapAnimation = {
      duration: 3000, // make the flying slow
      curve: 1.3, // change the speed at which it zooms out
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      pitch: 10,
      easing(t) {
        // eslint-disable-next-line prefer-exponentiation-operator
        return t < 0.5 ? 8 * t * t * t * t : 1 - Math.pow(-2 * t + 2, 4) / 2; // easeInOutQuart
      },
    };

    useEffect(() => {
      setTimeout(() => {
        map.current = new maplibregl.Map({
          container: mapContainer.current,
          style: process.env.GATSBY_MAPTILER_STYLE, // stylesheet location
          center: [-122.022202, 36.990878], // starting position [lng, lat]
          zoom: 9, // starting zoom
          maxZoom: 16,
          minZoom: 10,
        });

        map.current.scrollZoom.disable();
        map.current.dragRotate.disable();
        map.current.touchZoomRotate.disableRotation();

        map.current.addControl(
          new maplibregl.NavigationControl({ showCompass: false })
        );
        map.current.addControl(
          new maplibregl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true,
            },
            trackUserLocation: true,
          })
        );

        map.current.on("load", () => {
          setMapReady(true);
        });
      }, 1000);

      return () => {
        map.current = undefined;
      };
    }, []);

    useEffect(() => {
      if (map.current && mapReady) {
        if (markers.current) {
          markers.current.forEach(marker => marker.remove());
        }

        markers.current = [];
        results
          .filter(f => f.lat && f.lon)
          .sort((a, b) => b.lat - a.lat)
          .forEach(f => {
            const coordinates = [f.lon, f.lat];
            const marker = document.createElement("div");
            const root = createRoot(marker);

            root.render(
              <Marker
                label={query.length ? f.name : null}
                onClick={() => {
                  setCurrentArtist(f.uid);
                  const viewportOffset = mapContainer.current.clientWidth * 0.2;
                  map.current.easeTo({
                    center: coordinates,
                    zoom: 17,
                    offset: [viewportOffset || 0, 0],
                    ...mapAnimation,
                  });
                }}
              />
            );

            const nMarker = new maplibregl.Marker({ element: marker })
              .setLngLat(coordinates)
              .addTo(map.current);

            markers.current.push(nMarker);
          });
      }
    }, [results.length, map.current, activeFilter, mapReady]);

    return (
      <section className="relative -my-[2px] min-h-[60vh] border-y-2 border-black lg:min-h-[0]">
        <div className="absolute right-12 top-2 z-20 flex flex-grow flex-wrap gap-2 pl-2 md:mt-0">
          {filters?.map(filter => {
            const { uid, title } = filter;
            return (
              <Toggle
                key={uid}
                id={uid}
                name={title}
                label={title}
                checked={activeFilter === uid}
                onChange={updateFilters}
                className="after:bg-green peer-checked:bg-green peer-checked:after:bg-black"
              />
            );
          })}
          <div className="border-2 border-black bg-white px-1 pt-1">
            <Search
              fields={["name"]}
              content={filteredItems}
              placeholder={t("Search by Name", lang)}
              className="flex-auto"
              queryState={[query, setQuery]}
              resultState={[results, setResults]}
              noBorder
            />
          </div>
        </div>
        <div className="absolute inset-0 z-10 lg:relative lg:aspect-video lg:h-auto lg:w-full">
          <AnimatePresence>
            {currentArtist && (
              <m.div
                key={currentArtist}
                initial={{ x: "-100%" }}
                animate={{ x: 0 }}
                exit={{ x: "-100%" }}
                transition={{ type: "tween", duration: 0.5 }}
                className="absolute left-0 top-0 z-10 z-20 h-full w-[40vw] lg:bottom-0"
              >
                <Sidebar
                  {...artists?.find(a => a.uid === currentArtist)}
                  close={() => {
                    setCurrentArtist(null);
                    map.current.easeTo({
                      center: [-122.022202, 36.990878], // starting position [lng, lat]
                      zoom: 10, // starting zoom
                      offset: [0, 0],
                      ...mapAnimation,
                    });
                  }}
                />
              </m.div>
            )}
          </AnimatePresence>
          <div
            key="map"
            className="absolute inset-0 z-0 h-full w-full"
            ref={mapContainer}
          />
        </div>
      </section>
    );
  },
  () => true
);

export default OpenStudiosMap;
